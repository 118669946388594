// External Dependencies
import * as React from "react";
import {
  Edit,
  SimpleForm,
  TextInput,
  required,
  email,
  maxLength,
  minLength,
  BooleanInput,
  TextField,
  Labeled,
} from "react-admin";
import { RichTextInput } from "ra-input-rich-text";

const validateName = [required(), minLength(2), maxLength(15)];

const CompanyName = ({ record }) => {
  return <span>Company {record ? `"${record.name}"` : ""}</span>;
};
const CompanyEdit = (props) => (
  <Edit title={<CompanyName />} {...props}>
    <SimpleForm>
      <Labeled label="Id">
        <TextField source="id" />
      </Labeled>
      <BooleanInput source="isKeyops" />
      <TextInput source="name" validate={validateName} fullWidth />
      <TextInput source="contactEmail" validate={email()} fullWidth />
      <TextInput source="contactPhoneNumber" fullWidth />
      <RichTextInput source="bio" />
      <TextInput source="address" fullWidth />
    </SimpleForm>
  </Edit>
);

export default CompanyEdit;
