import {
  Countries,
  Genders,
  HcpTypes,
  Specialties,
  UserStates,
  SubSpecialtiesBySpecialty,
  SubSpecialties,
  PhysicianPracticeSettings,
  Provinces,
  States,
  StatesAndProvincesByCountry,
  InvitationStates,
  PharmacistPracticeSettings,
  PharmacistPracticeSpecialties,
  PharmacistRetailChains,
  PharmacistTypes,
  NurseOccupations,
  NursePracticeSettings,
  NurseSpecialties,
  NursePracticeSettingDetails,
  NurseEmploymentStatuses,
  EventTypes,
} from "@keyops-hcp/constants";

const getChoiceDisplay = (id) => {
  let name = id.replace(/[_-]/g, " "); // No need to escape the hyphen if it's at the beginning or end
  name = name.charAt(0).toUpperCase() + name.slice(1);
  return name;
};

const generateChoices = (values) => {
  return values.map((value) => {
    return { id: value, name: getChoiceDisplay(value) };
  });
};

const generateChoicesWithPrefix = (values, prefix) => {
  return values.map((value) => {
    return { id: value, name: prefix + getChoiceDisplay(value) };
  });
};

export const HcpTypeChoices = generateChoices(HcpTypes);
export const CountryChoices = generateChoices(Countries);
export const GenderChoices = generateChoices(Genders);
export const UserStateChoices = generateChoices(UserStates);
export const SpecialtyChoices = generateChoices(Specialties);
export const SubSpecialtyChoices = generateChoices(SubSpecialties);
export const SubSpecialtyChoicesBySpecialty = {};
Object.entries(SubSpecialtiesBySpecialty).forEach(
  ([specialty, subSpecialties]) => {
    SubSpecialtyChoicesBySpecialty[specialty] = generateChoices(subSpecialties);
  }
);
export const PhysicianPracticeSettingChoices = generateChoices(
  PhysicianPracticeSettings
);

export const ProvinceChoices = generateChoices(Provinces);
export const StateChoices = generateChoices(States);
export const StateAndProvinceChoices = Object.entries(
  StatesAndProvincesByCountry
).flatMap(([country, statesOrProvinces]) =>
  generateChoicesWithPrefix(statesOrProvinces, country + " - ")
);

export const InvitationStateChoices = generateChoices(InvitationStates);
export const PharmacistPracticeSettingChoices = generateChoices(
  PharmacistPracticeSettings
);
export const PharmacistPracticeSpecialtyChoices = generateChoices(
  PharmacistPracticeSpecialties
);
export const PharmacistRetailChainChoices = generateChoices(
  PharmacistRetailChains
);
export const PharmacistTypeChoices = generateChoices(PharmacistTypes);
export const NurseOccupationChoices = generateChoices(NurseOccupations);
export const NursePracticeSettingChoices = generateChoices(
  NursePracticeSettings
);
export const NurseSpecialtyChoices = generateChoices(NurseSpecialties);
export const NursePracticeSettingDetailChoices = generateChoices(
  NursePracticeSettingDetails
);
export const NurseEmploymentStatusChoices = generateChoices(
  NurseEmploymentStatuses
);
export const EventTypeChoices = generateChoices(EventTypes);
