// External Dependencies
import * as React from "react";
import { cloneElement } from "react";
import {
  Filter,
  List,
  Datagrid,
  TextField,
  EditButton,
  DateField,
  TextInput,
  FunctionField,
  SelectInput,
  Pagination,
  ReferenceManyField,
  downloadCSV,
  useListContext,
  TopToolbar,
  CreateButton,
  ExportButton,
  sanitizeListRestProps,
} from "react-admin";
import jsonExport from "jsonexport/dist";
import { XofYField, convertToXofY } from "../../../components/XofYField";

// Internal Dependencies
import CustomBulkDeleteButton from "../../../components/CustomBulkDeleteButton";
import {
  CountryChoices,
  HcpTypeChoices,
  SpecialtyChoices,
  StateAndProvinceChoices,
  UserStateChoices,
} from "../../../utils/choices.js";

const exporter = (users, _empty, dataProvider) => {
  const queryParams = { pagination: { page: 1, perPage: 100000 } };
  Promise.all([
    dataProvider.getList("Invitation", queryParams).then((result) => {
      console.log("invitations retrieved: " + result.total);
      const invitationsByUserId = new Map();
      result.data.forEach((invitation) => {
        const userId = invitation.userId;
        let result = invitationsByUserId.get(userId) ?? [];
        result.push(invitation);
        invitationsByUserId.set(userId, result);
      });
      users.forEach((user) => {
        const userId = user.id;
        user.invitations = convertToXofY(
          invitationsByUserId.get(userId),
          isCompletedInvitation
        );
      });
    }),
    dataProvider.getList("Referrals", queryParams).then((result) => {
      console.log("referrals retrieved: " + result.total);
      const referralsByUserId = new Map();
      result.data.forEach((referral) => {
        const senderId = referral.sentBy;
        let result = referralsByUserId.get(senderId) ?? [];
        result.push(referral);
        referralsByUserId.set(senderId, result);
      });
      users.forEach((user) => {
        const userId = user.id;
        user.referrals = convertToXofY(
          referralsByUserId.get(parseInt(userId)),
          isCompletedReferral
        );
      });
    }),
  ]).then((results) => {
    // TODO: use `results`?
    console.log(results);
    const usersForExport = users.map((user) => {
      return user;
      // const { backlinks, author, ...postForExport } = post; // omit backlinks and author
      // postForExport.author_name = post.author.name; // add a field
      // return postForExport;
    });
    jsonExport(
      usersForExport,
      {
        // headers: ['id', 'title', 'author_name', 'body'] // order fields in the export
      },
      (err, csv) => {
        downloadCSV(csv, "users"); // download as 'posts.csv` file
      }
    );
  });
};
const UserFilter = (props) => {
  return (
    <>
      <Filter {...props}>
        <TextInput label="Search" source="q" alwaysOn />

        <SelectInput source="specialty" choices={SpecialtyChoices} />
        <SelectInput source="country" choices={CountryChoices} />
        <SelectInput
          source="provinceOfPractice"
          choices={StateAndProvinceChoices}
        />
        <SelectInput source="state" choices={UserStateChoices} />
        <SelectInput source="hcpType" choices={HcpTypeChoices} />
      </Filter>
    </>
  );
};

const UserBulkActionButtons = (props) => (
  <React.Fragment>
    <CustomBulkDeleteButton {...props} />
  </React.Fragment>
);

const UserPagination = (props) => (
  <Pagination rowsPerPageOptions={[10, 25, 50, 100]} {...props} />
);

const ListActions = (props) => {
  const { className, filters, ...rest } = props;
  const {
    currentSort,
    resource,
    displayedFilters,
    filterValues,
    basePath,
    showFilter,
    total,
  } = useListContext();
  return (
    <TopToolbar className={className} {...sanitizeListRestProps(rest)}>
      {filters &&
        cloneElement(filters, {
          resource,
          showFilter,
          displayedFilters,
          filterValues,
          context: "button",
        })}
      <CreateButton basePath={basePath} />
      <ExportButton
        disabled={total === 0}
        resource={resource}
        sort={currentSort}
        filterValues={filterValues}
        maxResults={100000} // TODO: use maxResults from props?
      />
    </TopToolbar>
  );
};

const UserList = (props) => {
  return (
    <List
      pagination={<UserPagination />}
      filters={<UserFilter />}
      sort={{ field: "createdAt", order: "DESC" }}
      exporter={exporter}
      actions={<ListActions />}
      {...props}
    >
      <Datagrid rowClick="show" bulkActionButtons={<UserBulkActionButtons />}>
        <TextField source="id" />
        <FunctionField
          label="Name"
          render={(record) => `${record.firstName} ${record.lastName}`}
        />
        <TextField source="email" />
        <TextField source="state" />
        <TextField source="hcpType" />
        <TextField source="country" />
        <TextField source="provinceOfPractice" />
        <FunctionField
          label="Specialty"
          render={(record) => `${record.specialty}`}
        />
        <TextField label="Language preference" source="languagePreference" />

        <DateField source="createdAt" />
        <DateField source="updatedAt" />

        <ReferenceManyField
          reference="Invitation"
          target="userId"
          perPage={9999}
          label="Invitations"
        >
          <XofYField numeratorFilter={isCompletedInvitation} />
        </ReferenceManyField>
        <ReferenceManyField
          reference="Referrals"
          target="sentBy"
          perPage={9999}
          label="Referrals"
        >
          <XofYField numeratorFilter={isCompletedReferral} />
        </ReferenceManyField>
        <EditButton />
      </Datagrid>
    </List>
  );
};

export default UserList;

function isCompletedInvitation(record) {
  return record.state === "completed";
}

function isCompletedReferral(record) {
  return record.completed;
}
