import React from "react";
import { Menu } from "react-admin";
import LabelIcon from "@mui/icons-material/Label";

export const CustomMenu = () => (
  <Menu>
    <Menu.DashboardItem />
    <Menu.ResourceItem name='Admins' />
    <Menu.ResourceItem name='Company' />
    <Menu.ResourceItem name='Users' />
    <Menu.ResourceItem name='Engagement' />
    <Menu.ResourceItem name='Invitation' />
    <Menu.ResourceItem name='Referrals' />
    <Menu.ResourceItem name='Payment' />
    <Menu.ResourceItem name='RewardsTransaction' />
    <Menu.ResourceItem name='RewardsPeriod' />
    <Menu.ResourceItem name='EngagementFile' />
    <Menu.ResourceItem name='FeatureSwitches' />
    <Menu.ResourceItem name='OnboardingLink' />
    <Menu.ResourceItem name='PhysicianOnboarding' />
    <Menu.ResourceItem name='Notification' />
    {/* @ts-expect-error - MenuItem demanda more props  */}
    <Menu.Item
      to='/adboards/videos'
      primaryText='AdBoards'
      leftIcon={<LabelIcon />}
    />
  </Menu>
);
