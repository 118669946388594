// External Dependencies
import * as React from "react";
import {
  TextField,
  EditButton,
  Show,
  SimpleShowLayout,
  RichTextField,
  TopToolbar,
} from "react-admin";

const PostShowActions = ({ basePath, data }) => (
  <TopToolbar>
    <EditButton basePath={basePath} record={data} />
  </TopToolbar>
);

const CompanyShow = (props) => (
  <Show actions={<PostShowActions />} {...props}>
    <SimpleShowLayout>
      <TextField source="name" />
      <TextField source="contactEmail" />
      <TextField source="contactPhoneNumber" />
      <RichTextField label="Bio" source="bio" />
      <TextField source="address" />
    </SimpleShowLayout>
  </Show>
);

export default CompanyShow;
