import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { VideosOutput } from "@keyops-hcp/sequelize-models";

import { getAllVideos } from "./api";

export const VideosList = () => {
  const [videos, setAllVideos] = useState<VideosOutput[]>([]);

  const handleGetAllVideos = async () => {
    const results = await getAllVideos();

    if (results) {
      setAllVideos(results);
    }
  };

  useEffect(() => {
    handleGetAllVideos();
  }, []);

  return (
    <React.Fragment>
      <div>Videos</div>
      {videos ? (
        <ul>
          {" "}
          {videos.length > 0 &&
            videos.map((video) => (
              <li key={video.id}>
                <Link to={video.id}>{video.title}</Link>
              </li>
            ))}
        </ul>
      ) : (
        <div>Loading data...</div>
      )}
    </React.Fragment>
  );
};
