import React, { useEffect, useState } from "react";
import ReactPlayer from "react-player";
import { useParams } from "react-router-dom";
import { Box, Container, Snackbar, TextField, Typography } from "@mui/material";
import { VideosOutput } from "@keyops-hcp/sequelize-models";

import { getOneVideo } from "./api";
import { buildVimeoURL } from "../../../utils/video";

export const VideoShow = () => {
  const { id } = useParams();

  const [video, setVideo] = useState<VideosOutput>();
  const [isToastOpen, setIsToastOpen] = useState<boolean>(false);
  const [errorMessage, setErrorMessage] = useState<string | undefined>(
    undefined
  );

  const handleGetOneVideo = async (id: string) => {
    try {
      const results = await getOneVideo(id);
      console.log(results);

      if (results) {
        setVideo(results);
      }
    } catch (error) {
      console.error(error);
      setErrorMessage(`Error fetching the video`);
      setIsToastOpen(true);
    }
  };

  useEffect(() => {
    if (id) handleGetOneVideo(id);
  }, [id]);

  return (
    <Container>
      <Snackbar
        anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
        open={isToastOpen}
        autoHideDuration={5000}
        message={errorMessage}
        sx={{ zIndex: 100000 }}
      />
      {video ? (
        <Box padding={"24px"}>
          <Typography variant='h1' fontSize={"16px"} fontWeight={400}>
            Video
          </Typography>
          <Box display={"block"} width={"fit-content"} margin={"auto"}>
            <ReactPlayer url={buildVimeoURL(video.vimeoId)} controls />
            <TextField
              label='Title'
              defaultValue={video.title}
              variant='outlined'
              fullWidth
            />

            <TextField
              label='Description'
              defaultValue={video.description}
              variant='outlined'
              multiline
              fullWidth
              maxRows={4}
            />
          </Box>
        </Box>
      ) : (
        <div>Loading data...</div>
      )}
    </Container>
  );
};
