import axios from "axios";
import { VideosOutput } from "@keyops-hcp/sequelize-models";
import { getAPIUrl } from "../../../utils/env";

const apiUrl = getAPIUrl();

export const getAllVideos = async (): Promise<VideosOutput[]> => {
  try {
    const response = await axios.get(`${apiUrl}/videos`, {
      headers: {
        keyops__accesstokens: localStorage.getItem("token"),
        userType: "admin",
      },
    });
    return response.data.data;
  } catch (error) {
    console.error("Error fetching data getAllVideos:", error);
    throw error;
  }
};

export const getOneVideo = async (id: string): Promise<VideosOutput> => {
  try {
    const response = await axios.get(`${apiUrl}/videos/${id}`, {
      headers: {
        keyops__accesstokens: localStorage.getItem("token"),
        userType: "admin",
      },
    });
    return response.data.data;
  } catch (error) {
    console.error("Error fetching data getOneVideo:", error);
    throw error;
  }
};
