// External Dependencies
import * as React from "react";
import {
  Create,
  SimpleForm,
  TextInput,
  required,
  email,
  maxLength,
  minLength,
  BooleanInput,
} from "react-admin";
import { RichTextInput } from "ra-input-rich-text";

const validateName = [required(), minLength(2), maxLength(15)];

const CompanyCreate = (props) => (
  <Create {...props}>
    <SimpleForm>
      <BooleanInput source="isKeyops" />
      <TextInput source="name" validate={validateName} fullWidth />
      <TextInput source="contactEmail" validate={email()} fullWidth />
      <TextInput source="contactPhoneNumber" fullWidth />
      <RichTextInput source="bio" fullWidth />
    </SimpleForm>
  </Create>
);

export default CompanyCreate;
